import React from 'react';
import Button from '@material-ui/core/Button';
import Card from '@material-ui/core/Card';
// import CardActions from '@material-ui/core/CardActions';
import { Link } from "gatsby";
import CardContent from '@material-ui/core/CardContent';
import CardMedia from '@material-ui/core/CardMedia';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import { makeStyles } from '@material-ui/core/styles';
import Container from '@material-ui/core/Container';
import Layout from "../components/layout";

import LifestyleDesign from "../images/lifestyle-design.png";
import MazeCrawler from "../images/maze-crawler.png";
import SoilNerd from "../images/soil-nerd.png";
import ArrowIcon from "../images/arrow-icon.png";

const useStyles = makeStyles(theme => ({
  icon: {
    display: 'block',
    marginLeft: 'auto',
    marginRight: 'auto',
    maxWidth: '30px',
  },
  heroContent: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(8, 0, 6),
  },
  heroButtons: {
    marginTop: theme.spacing(4),
  },
  ctaButtons: {
    backgroundColor: '#ef6830',
    color: '#202020',
  },
  ctaLinks: {
    textDecoration: 'none',
  },  
  ctaLinksSecondary: {
    textDecoration: 'none',
  },
  cardGrid: {
    paddingTop: theme.spacing(8),
    paddingBottom: theme.spacing(8),
  },
  card: {
    height: '100%',
    display: 'flex',
    flexDirection: 'column',
  },
  cardMedia: {
    paddingTop: '56.25%', // 16:9
  },
  cardContent: {
    flexGrow: 1,
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
}));

const cards = [
  {image: SoilNerd, heading: 'Soil Nerd', description:'Automated agronomy recommendations app. Python + Django + VueJS + Shopify.', title: 'Soil Nerd'}, 
  {image: LifestyleDesign, heading: 'Lifestyle Design', description:'Personal goal and habit tracking app. ReactJS + Ruby on Rails.', title: 'LifestyleDesign'}, 
  {image: MazeCrawler, heading: 'Maze Crawler', description:'Desktop dungeon crawler game. Ruby + Gosu.', title: 'MazeCrawler'}, 
];

export default function SoftwarePage() {
  const classes = useStyles();

  return (
    <Layout>
    <React.Fragment>
      <main>
        {/* Hero unit */}
        <div className={classes.heroContent}>
          <Container maxWidth="m">
            <Typography component="h1" variant="h2" align="center" gutterBottom>
              Reliable Software
            </Typography>
            <Typography variant="h5" align="center" color="textSecondary" paragraph>
              to scale impact with websites that work
            </Typography>
            <div className={classes.heroButtons}>
              <Grid container spacing={2} justify="center">
                <Grid item>
                <Link to="/contact" className={classes.ctaLinks}>
                  <Button variant="contained" className={classes.ctaButtons}>
                    Contact Carl
                  </Button>
                </Link>
                </Grid>
                <Grid item>
                <Link to="/software-development-101" className={classes.ctaLinksSecondary}>
                  <Button variant="outlined">
                    Learn more about software
                  </Button>
                </Link>
                </Grid>
              </Grid>
            </div>
          </Container>
        </div>
        <img src={ArrowIcon} className={classes.icon} alt=""></img>
        <Container className={classes.cardGrid} maxWidth="md">
          {/* End hero unit */}
          <Grid container spacing={4}>
            {cards.map(card => (
              <Grid item key={card} xs={12} sm={6} md={4}>
                <Card className={classes.card}>
                  <CardMedia
                    className={classes.cardMedia}
                    image={card.image}
                    title={card.title}
                  />
                  <CardContent className={classes.cardContent}>
                    <Typography gutterBottom variant="h5" component="h2">
                      {card.heading}
                    </Typography>
                    <Typography>
                    {card.description}
                    </Typography>
                  </CardContent>
                  {/* <CardActions>
                    <Button size="small" color="primary">
                      View
                    </Button>
                    <Button size="small" color="primary">
                      Edit
                    </Button>
                  </CardActions> */}
                </Card>
              </Grid>
            ))}
          </Grid>
        </Container>
      </main>
    </React.Fragment>
    </Layout>
  );
}